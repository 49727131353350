import React,{useEffect} from 'react'
import "./ContactFeatures.css"
import CircleButton from "../Buttons/CircleButton"
import Aos from 'aos'
import "aos/dist/aos.css"

function ContactFeatures(props) {

  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])

  return (
    <div className="homefeatureContainer">
    <div className="contact featureContainer">
    {props.data && props.data.map((data,i)=>(
    <div key={i}>
    <div data-aos="fade-up" className="featureBox">
            <div className="text-center">
            <p className="font-medium w-1/2 mx-auto dark:bg-gray-900 bg-white featureTitle text-black dark:text-white">{data.title}</p>
            <p className="mt-7 featureText lg:w-3/4 lg:mx-auto text-black dark:text-white">{data.description}</p>
            </div>
            <div className="buttonPosition flex justify-center">
            <a href={data.link}><CircleButton /></a>
            </div>
    </div>
    </div>
    ))}
    </div>
    </div>
  )
    }


export default ContactFeatures