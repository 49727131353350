import React from 'react'
import "./Button.css"


function ButtonOutline(props) {
  return (
    <button className="buttonOutline">
        {props.name}
    </button>
  )
}

export default ButtonOutline