import React,{useState} from 'react'
import Menu from "../../assets/images/Menu.svg"
import Logo from "../../assets/images/logo.svg"
import fullLogo from "../../assets/images/KickoffFullLogo.png"
import closeMenu from "../../assets/images/Close.svg"
import Play from "../../assets/images/play button.svg"
import {FaTwitter,FaLinkedin} from "react-icons/fa"
// import RoundedButton from "../../Components/Buttons/RoundedButton"
import Toggle from '../../Components/ThemeToggle';
import { useHistory } from "react-router-dom";
import "./NavMenu.css"

function NavMenu(props) {
    const history = useHistory()
    const [logo,setLogo] = useState(Logo)
    const [menuOpened,setMenuOpened] = useState(false)
    function openNav() {
        setMenuOpened(true)
        document.getElementById("myNav").style.width = "100%";
      }
      
      function closeNav() {
        setMenuOpened(false)
        document.getElementById("myNav").style.width = "0%";
      }


      window.addEventListener('scroll',function(){
        if(window.scrollY > 40){
            document.getElementById("menu").classList.add("menuBackground")
            setLogo(fullLogo)
        } else if(window.scrollY < 39){
            document.getElementById("menu").classList.remove("menuBackground")
            setLogo(Logo)
        }
      })

      window.addEventListener('scroll',function(){
        if(window.scrollY > 590){
            document.getElementById("mobileMenu").classList.add("menuBackground")
            
        } else{
            document.getElementById("mobileMenu").classList.remove("menuBackground")
    
            
        }
      })

  return (
    <div className="navStyle">
        {/* Laptop Menu */}
        <div id="menu" className="largescreen p-4 lg:flex">
            <div className="w-1/12 logoPosition">
            <img style={{cursor:"pointer"}} onClick={()=>history.push("/")} width="80px" height="97px" src={logo}  alt="kickofflogo"/>
            </div>
            <div className="w-8/12 mt-6 w-6/8 p-2 mt-3">
                <div className="menuItems flex text-white justify-center">
                    <li onClick={()=>history.push("/portfolio")}><span className={props.url ==='/portfolio' ? "peopleactiveMenu":"notActiveMenu"}>Portfolio</span> <span className={props.url ==='/portfolio' ? "activeMenu":"notactiveMenu"}>hi</span></li>
                    <li onClick={()=>history.push("/people")}><span className={props.url ==='/people' ? "peopleactiveMenu":"notActiveMenu"}>People </span> <span className={props.url ==='/people' ? "activeMenu":"notactiveMenu"}>hi</span></li>
                    <li onClick={()=>history.push("/contact")}><span  className={props.url ==='/contact' ? "peopleactiveMenu":"notActiveMenu"}>Contact</span> <span className={props.url ==='/contact' ? "activeMenu":"notactiveMenu"}>hi</span></li>
                </div>
            </div>
            <div className="mt-3 flex w-2/12">
    <div className="cursor-pointer ml-4 mt-6 flex">
        <a href="https://twitter.com/folasanwo"><FaTwitter className="cursor-pointer w-5 h-5 text-white mr-12" /></a> 
        <a style={{zIndex:"40"}} href="https://www.linkedin.com/company/kickoff-africa"><FaLinkedin className="cursor-pointer w-5 h-5 text-white" /></a>
    </div>
    <div className="-ml-14 mt-4">
    <Toggle />
    </div>
            </div>
        </div>





        {/* Mobile Menu */}
        <div id="mobileMenu" className="lg:hidden px-6 p-2 flex justify-between">
            <div>
            <img style={{cursor:"pointer"}} src={logo} width="50px" height="50px" onClick={()=>{history.push("/")}} alt="kickofflogo"/>
            </div>
            <div className="flex">
            <div> 
                <Toggle />
                </div>
            <div className="p-2 mt-3">
            <img onClick={openNav} src={Menu}  alt="menuIcon"/>
            </div>
            </div>
            
        </div>



        <div id="myNav" className="overlay">
        <div className="p-6 flex justify-between">
            <div className="mt-3">
            <img style={{cursor:"pointer"}} onClick={()=>{window.location.href="/"}} src={Logo}  alt="kickofflogo"/>
            </div>
            <div className="mt-3">
            <img onClick={closeNav} src={closeMenu}  alt="menuIcon"/>
            </div>
        </div>
  <div className="overlay-content">
    <a className={menuOpened ?"animate__animated animate__fadeInDown animate__fast":null} href="/portfolio">Portfolio</a>
    <a className={menuOpened ?"animate__animated animate__fadeInDown":null} href="/people">People</a>
    <a className={menuOpened ? "animate__animated animate__fadeInDown animate__slower":null} href="/contact">Contact</a>
    <div className="mt-4 flex justify-center">
    <img src={Play} alt="" onClick={closeNav}  />
    </div>
    <div className="mt-7 flex justify-center">
    <FaTwitter onClick={()=>window.location.href="https://twitter.com/folasanwo"} className="w-8 h-8 text-white mr-8" /> <FaLinkedin onClick={()=>window.location.href="https://www.linkedin.com/company/kickoff-africa"} className="w-9 h-9 text-white" />
    </div>
  </div>
  </div>


        {/* <div className="absolute" style={{height:"100%"}}>
            <div className="flex justify-between">
            <div>
            <img src={Logo}  alt="kickofflogo"/>
            </div>
            <div className="mt-3">
            
            </div>
            </div>
        </div> */}

    </div>
  )
}

export default NavMenu