import React,{useEffect} from 'react'
import globe from "../../assets/images/globe.svg"
import "./About.css"
// import Button from '../Buttons/CircleButton'
import Count from '../Counter/Counter'
import data from './target.json'
import startups from './startups.json'
import investment from './investment.json'
import Features from '../Features/Features'
import imageOne from "../../assets/images/Microscope.svg"
import imageTwo from "../../assets/images/Group.svg"
import imageThree from "../../assets/images/handshake.svg"
import Aos from 'aos'
import "aos/dist/aos.css"


function About() {
    useEffect(()=>{
        Aos.init({ duration:1000 })
      },[])

    const featureOne = {
        "image":imageOne,
        "title":"Startup discovery",
        "description":"We have a wide and deep pipeline for finding great founders early"
    }

    const featureTwo= {
        "image":imageTwo,
        "title":"People Focused",
        "description":"We believe the best support we can offer (after cash) is helping our founders connect with the right people"
    }

    const featureThree = {
        "image":imageThree,
        "title":"Real Partnership",
        "description":"We have designed and led some of Africa’s best accelerator programs."
    }




  return (
    <div style={{height:"100%"}}>
        <div data-aos="fade-up" className="my-6">
        <h2 id="about"  className="text-center text-black text-2xl font-bold dark:text-white">ABOUT US</h2>
        </div>
        <div className="aboutContainer">
        <div data-aos="fade-right" className="centerImg">
        <img src={globe} className="imageRotateHorizontal globeSize" alt="globe"/>
        </div>
        <div data-aos="fade-up" className="aboutText text-black dark:text-white">
            <div>
            There’s a wave of technology entrepreneurs building Africa’s future and startup ecosystems across Africa are rapidly developing with venture backed startups maturing and the long awaited paths to exits are  starting to appear.
            <br/> <br/>
            Kickoff Africa is an early stage investment vehicle for African entrepreneurs aimed at providing capital, custom support and the right network to help startups grow. 
            {/* <div id="portfolio" data-aos="fade-up" className="aboutButton mt-12">
            <Button url="/people"/>
            </div> */}
            </div>
        </div>
        </div>


        {/* Counter  */}
       <div data-aos="fade-up" className="counterContainer">
        <div className="lg:w-1/3">
        {data.counts.map(count => <Count key={count.id} data={count}/>)}
        </div>

        <div className="lg:w-1/3">
        {startups.counts.map(count => <Count key={count.id} data={count}/>)}
        </div>

        <div className="lg:w-1/3">
        {investment.counts.map(count => <Count key={count.id} data={count}/>)}
        </div>
        </div>


        {/* Features */}
        <div data-aos="fade-up" className="featuresContainer">
        <h2 className="text-center kickoffAdvantage text-black text-2xl font-bold dark:text-white">THE KICKOFF ADVANTAGE</h2>
        <div  className="featuresContainerFlex">
            <div className="lg:w-1/3">
            <Features image={featureOne.image} title={featureOne.title} description={featureOne.description}/>
            </div>

            <div className="lg:w-1/3">
            <Features image={featureTwo.image} title={featureTwo.title} description={featureTwo.description}/>
            </div>

            <div className="lg:w-1/3">
            <Features image={featureThree.image} title={featureThree.title} description={featureThree.description}/>
            </div>
            </div>
        </div>





    </div>
  )
}

export default About