//ThemeToggle.js
import React from 'react';
import { FaSun, FaMoon} from "react-icons/fa";
import { ThemeContext } from './ThemeContext';
import "../Components/NavMenu/NavMenu.css"

const Toggle = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);

    function switchOn(){
        window.location.reload()
        setTheme(theme === 'dark' ? 'light' : 'dark') 
       
    }

    function switchOff(){
        window.location.reload()
        setTheme(theme === 'dark' ? 'light' : 'dark')
        
    }

    return (
        <div style={{background:"#0077FF"}} className="togglePosition  transition duration-500 mt-2 ease-in-out rounded-full p-2">
            {theme === 'dark' ? (
                <FaSun
                    onClick={switchOn}
                    className="text-white dark:text-white text-xl cursor-pointer"
                />
            ) : (
                    <FaMoon
                        onClick={switchOff}
                        className="text-white dark:text-white text-xl cursor-pointer"
                    />
                )}
        </div>
    );
};

export default Toggle;