import React, { useState } from "react";

import Carousel from "react-elastic-carousel";
import Item from "./item";
import "./Portfolio.css";
// import image from "../../assets/images/portfolio.svg"
import AfropolitanLight from "../../assets/images/Afropolitan 1.png"
import AfropolitanDark from "../../assets/images/Afropolitan 2.png"
import BitnobLight from "../../assets/images/Rectangle-6.png"
import BitnobDark from "../../assets/images/Rectangle-7.png"
import BlocLight from "../../assets/images/bloc-black.png"
import BlocDark from "../../assets/images/bloc-black-1.png"
import BrassLight from "../../assets/images/Rectangle (5).png"
import BrassDark from "../../assets/images/Rectangle-1.png"
import CovaLight from "../../assets/images/Rectangle-2.png"
import CovaDark from "../../assets/images/Rectangle-3.png"
import EarnipayLight from "../../assets/images/earningpayLight.png"
import EarnipayDark from "../../assets/images/earningpayDark.png"
import FloatLight from "../../assets/images/wordmark-dark 2.png"
import FloatDark from "../../assets/images/wordmark-dark 1.png"
import HeyFoodLight from "../../assets/images/HeyFood.png"
import HeyFoodDark from "../../assets/images/HeyFood-1.png"
import KlumpLight from "../../assets/images/Group-2.png"
import KlumpDark from "../../assets/images/Group-3.png"
import EdukoyaLight from "../../assets/images/Group-1.png"
import EdukoyaDark from "../../assets/images/Group.png"
import MyStashLight from "../../assets/images/Mystash.png"
import MyStashDark from "../../assets/images/Mystash-1.png"

const breakPoints = [
  { width:1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 }
];

export default function App() {
  const [items] = useState(
    [
      {id:1,image:AfropolitanLight,imageDark:AfropolitanDark,url:"https://afropolitangroup.com"}, 
      {id:3,image:BitnobLight,imageDark:BitnobDark,url:"https://bitnob.com"}, 
      {id:4,image:BlocLight,imageDark:BlocDark,url:"https://www.blochq.io"}, 
      {id:5,image:BrassLight,imageDark:BrassDark,url:"https://www.trybrass.com"}, 
      {id:6,image:CovaLight,imageDark:CovaDark,url:"https://getcova.com"}, 
      {id:7,image:EarnipayLight,imageDark:EarnipayDark,url:"https://earnipay.com"}, 
      {id:8,image:EdukoyaLight,imageDark:EdukoyaDark,url:"https://edukoya.com"},
      {id:9,image:FloatLight,imageDark:FloatDark,url:"https://float.africa"},
      {id:10,image:HeyFoodLight,imageDark:HeyFoodDark,url:"https://heyfood.africa"},
      {id:11,image:KlumpLight,imageDark:KlumpDark,url:"https://useklump.com"},
      {id:12,image:MyStashLight,imageDark:MyStashDark,url:"https://mystash.ng"},

    ]
    );

//   const addItem = () => {
//     const nextItem = Math.max(1, items.length + 1);
//     setItems([...items, nextItem]);
//   };

//   const removeItem = () => {
//     const endRange = Math.max(0, items.length - 1);
//     setItems(items.slice(0, endRange));
//   };

  return (
    <div className="App">
      <div className="controls-wrapper">
        {/* <button onClick={removeItem}>Remove Item</button>
        <button onClick={addItem}>Add Item</button> */}
      </div>
      <div className="carousel-wrapper">
        <Carousel breakPoints={breakPoints}>
          {items.map((item,i) => (
            <Item key={i}><img src={localStorage.getItem("color-theme") === "dark" ? item.image:item.imageDark} alt="img"/></Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}