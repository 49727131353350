import React, { useRef, useEffect, useState } from 'react';
import "./Counter.css"

function Count (props){
  // label of counter
  // number to increment to
  // duration of count in seconds
  const {label,number, duration,prefix,suffix } = props.data
  const myRef = useRef()
  const [myElementisVisible,setVisible] = useState()

  // number displayed by component
  const [count, setCount] = useState("0")

  useEffect(() => {
    const observer = new IntersectionObserver((entries)=>{
    const entry = entries[0]
    setVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current)
    if(myElementisVisible){
    let start = 0;
    // first three numbers from props
    const end = parseInt(number.substring(0,3))
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter 
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3))
      if (start === end) clearInterval(timer)       
    }, incrementTime);
  }
    // dependency array
  }, [number, duration,myElementisVisible]);


  window.addEventListener('scroll', function(){
    if(window.scrollY > 5000){
    let start = 0;
    // first three numbers from props
    const end = parseInt(number.substring(0,3))
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter 
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3))
      if (start === end) clearInterval(timer)       
    }, incrementTime);
  }
  })


  return (
    <div>
    <div ref={myRef}>
      <h3  className="counterNumber text-center font-bold dark:text-white text-black">
        {prefix}{count}{suffix}
      </h3>
      <p  className="counterLabel pr-3 text-center dark:text-white text-black">{label}</p>
    </div>
    </div>
  );
}

export default Count;