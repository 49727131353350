import React,{useEffect} from 'react'
import "./PortfolioGrid.css"
import Aos from 'aos'
import "aos/dist/aos.css"

function PortfolioGrid(props) {



  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])


  return (
    <div data-aos="fade-up" className="text-center gridContainer">
        <div className="cursor-pointer flex justify-center">
        <img onClick={()=>window.location.href=props.url} src={localStorage.getItem("color-theme") === "dark" ? props.image : props.imageDark} className="p-2" style={{borderRadius:"6px",objectFit:"contain",width:"200px",height:"200px"}} alt="#"/>
        </div>
        <p className="mb-5 mt-3 w-3/4 mx-auto text-black dark:text-white">
          {props.description.length<=57 ? props.description : props.description.slice(0,57)+"..."}
          </p>
        <div className="flex justify-center">
        <a href={props.url}><div style={{color:"#0077FF",fontSize:"16px"}} className="uppercase">{props.title}</div></a>
    </div>
    </div>
  )
}

export default PortfolioGrid