import React,{useState,useEffect} from 'react'
import NavMenu from '../../Components/NavMenu/NavMenu'
import PeopleHero from "../../Components/PeopleHero/PeopleHero"
import PeopleCard from '../../Components/PeopleCard/PeopleCard'
import PeopleCircle from "../../Components/PeopleCard/PeopleCircle"
import Footer from '../../Components/Footer/Footer'
import Fola from  "../../assets/images/Fola Aina.jpg"
import Lawrence from "../../assets/images/Lawrence.jpg"
import Maya from "../../assets/images/Maya.jpg"
import Ricardo from "../../assets/images/Ricardo.jpg"
import Jessica from "../../assets/images/Jessica.jpg"
import Odun from "../../assets/images/Odun.jpg"
import Oo from "../../assets/images/Oo.jpg"
import Dolapo from "../../assets/images/Dolapo.jpg"
import Lamide from "../../assets/images/lamide.jpg"
import adaobi from "../../assets/images/adaobi.jpg"
import Aos from 'aos'
import "aos/dist/aos.css"

function People() {

  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])

 const [team] = useState([
     {name:"Fola Olatunji-David",role:"Founding Partner",linkedin:"https://www.linkedin.com/in/folasanwo/",image:Fola},
     {name:"Lawrence Gbadamosi",role:"Venture Partner",linkedin:"https://www.linkedin.com/in/lawrence-gbadamosi-80418b53/",image:Lawrence},
     {name:"Maya Caddle",role:"Venture Partner",linkedin:"https://www.linkedin.com/in/maya-caddle/",image:Maya},
 ])

 const [team2] = useState([
  {name:"Dolapo Olayoriju", role:"Chief Of Staff" , linkedin:"https://www.linkedin.com/in/dolapo-olayoriju-211269121/",image:Dolapo},
  {name:"Lamide Johnson",role:"Director of Programmes",linkedin:"https://www.linkedin.com/in/lamidejohnson/",image:Lamide},
])

 const [board] = useState([
  {name:"Dr. Ricardo Schaefer",role:"Target Global",linkedin:"https://www.linkedin.com/in/ricardoschaefer/",image:Ricardo},
  {name:"Jessica Hope",role:"Wimbart",linkedin:"https://www.linkedin.com/in/jessica-hope-wimbart/",image:Jessica},
  {name:"Odun Longe",role:"TLP Advisory",linkedin:"https://www.linkedin.com/in/odunoluwa/",image:Odun},
])

const [board2] = useState([
  {name:"Oo Nwoye",role:"Amazon, TechCircle",linkedin:"https://www.linkedin.com/in/oonwoye/",image:Oo},
  {name:"Adaobi Okafor",role:"Grooming Endowment Trust",linkedin:"https://www.linkedin.com/in/adaobi-okafor-fcca-5315b212/",image:adaobi},
])



  return (
    <div style={{fontFamily:"Inter"}}>
        <NavMenu url={window.location.pathname} />
        <PeopleHero  title="The team democratizing access to capital for entrepreneurs in Africa" subtitle=""/>

        <div className="mt-12 text-gray-900 dark:text-white text-center text-2xl font-bold">
          THE TEAM
        </div>

        <div data-aos="fade-up" className="mt-7 lg:flex lg:justify-center">
        {team && team.map((team,i)=>(
        <div key={i}>
        <PeopleCard name={team.name} role={team.role} image={team.image} linkedin={team.linkedin} />
        </div>
        ))}
        </div>

        <div data-aos="fade-up" className="mt-7 lg:flex lg:justify-center">
        {team2 && team2.map((team,i)=>(
        <div key={i}>
        <PeopleCard name={team.name} role={team.role} image={team.image} linkedin={team.linkedin} />
        </div>
        ))}
        </div>


        <div data-aos="fade-up" className="mt-7 text-gray-900 dark:text-white text-center text-2xl font-bold">
          ADVISORY TEAM
        </div>


        <div className="my-12">
        <div className="lg:flex lg:justify-center">
        {board && board.map((team,i)=>(
        <div key={i}>
        <PeopleCircle name={team.name} role={team.role} linkedin={team.linkedin} image={team.image} />
        </div>
        ))
    }
        </div>

        <div className="mt-7 lg:flex lg:justify-center">
        {board2 && board2.map((team,i)=>(
        <div key={i}>
        <PeopleCircle name={team.name} role={team.role} linkedin={team.linkedin} image={team.image} />
        </div>
        ))
    }
        </div>
        </div>


        <div className="bg-gray-900  py-7" style={{marginTop:"100px",marginBottom:"-90px"}}>
        <Footer />
        </div>
    </div>
  )
}

export default People