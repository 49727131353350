import React,{useState} from 'react'
import "./Button.css"
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

function RoundedButton(props) {

  const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;


let [color] = useState("white");

  return (
    <button onClick={() => props.childToParent()} className="buttonRound flex">
         <span>{props.name}</span><span className="m-1"><ClipLoader color={color} loading={props.loading} css={override} size={15} /></span>
    </button>
  )
}

export default RoundedButton