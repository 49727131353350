import React from 'react'
import NavMenu from '../../Components/NavMenu/NavMenu'
import Footer from '../../Components/Footer/Footer'
import Hero from '../../Components/Hero/Hero'
import ContactFeatures from "../../Components/ContactFeatures/ContactFeatures"

function Contact() {
  const data = [
    {title:"PITCHING",description:"Are you looking for investment in your startup?",link:"https://docs.google.com/forms/d/e/1FAIpQLSejRzKeMCOUkUWrBbQAAgnoQbNv-RUC4-GgzW9kU4qutUJlSA/viewform"},
    {title:"INVEST",description:"Do you want to get in touch with a Startup?",link:"https://docs.google.com/forms/d/e/1FAIpQLSdF_I7kqeZ0c2bynph0unXyF_VUbQHTrS-N1i1YLeZWexkmwQ/viewform"},
    {title:"REFERRAL",description:"Do you want to refer a Startup?",link:"https://docs.google.com/forms/d/e/1FAIpQLSemcKNwDuGYyqHJpnv6zT9LaGHrM8x9AsuoCaVMGUn2KuMUeQ/viewform"},
    {title:"ENQUIRY",description:"Do you have any general questions?",link:"mailto:dolapo@kickoff.africa"},
  ]
  return (
    <div style={{fontFamily:"Inter"}}>
        <NavMenu url={window.location.pathname} />
        <Hero  title="What Would you like to Contact us about?" subtitle="SAY HELLO AND KICKOFF WITH US"/>

        <ContactFeatures data={data} />

        <div className="bg-gray-900 py-7" style={{marginTop:"100px",marginBottom:"-90px"}}>
        <Footer />
        </div>
    </div>
  )
}

export default Contact