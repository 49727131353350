import React from 'react'
import backgroundImage from "../../assets/images/Africa.svg"
import "./Blog.css"
import ReplyArrow from "../../assets/images/ReplyArrow.svg"
// import ArticleCarousel from './ArticleCarousel'

function BlogWeb(props) {
    function goLink(e){
        window.location.href= e
    }
    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
    let today = new Date()
    let date = monthNames[(today.getMonth())] +' '+today.getDate()+' '+today.getFullYear() 
  return (
    <div>
        <div className="blogImageContainer">
            <img src={backgroundImage} alt="img" style={{margin:"auto",width:"50%"}} />
            <div className="after">
            <div className="w-5/6 mx-auto flex">
                {props.articles && props.articles.map((article,i)=>(
                    <div key={i} className="w-1/3 p-4">
                    <div onClick={()=>goLink(article.metadata.website)} className="blogArticleContainer">
                        <img src={article.metadata.banner} style={{cursor:"pointer",height:"300px",width:"100%",objectFit:"cover",margin:"auto"}} alt="" />
                        <div style={{height:"340px"}} className="p-6 dark:bg-transparent bg-white">
                        <div className="flex justify-between">
                            <button style={{background:"#0077FF",height:"30px",padding:"0px 25px 0px 25px"}} className="text-xs rounded-full">Startup News</button>
                            <p className="text-center text-black font-bold dark:text-white mb-8">{date}</p>
                        </div>
                        <p className="mt-7 text-center text-black  dark:text-white mb-8">{article.metadata.description}</p>

                        <img onClick={()=>goLink(article.metadata.website)} className="cursor-pointer dark:bg-gray-900 bg-blue-600 p-3 rounded-full" src={ReplyArrow} alt="readMore"/>
                        </div>
                    </div>
                    </div>
                ))}
            </div>
        </div>
        </div>

    </div>
  )
}

export default BlogWeb