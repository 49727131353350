import React, {
    useState,useEffect
} from 'react'
import BlogMobile from './BlogMobile'
import BlogWeb from "./BlogWeb"
import axios from 'axios'
import './Blog.css'

function Blog() {

    const [articles,setArticles] = useState([])


    useEffect(()=>{
      axios({
        method:"GET",
        url:"https://kickoffapi.herokuapp.com/articles"
      }).then((response)=>{
        setArticles(response.data.slice(0,3))
      })
    },[])

    //  const [articles,setArticles]  = useState([])

    //     axios({
    //         method:"GET",
    //         url:"https://techpoint.africa/wp-json/wp/v2/posts"
    //     }).then((response)=>{
    //         response.data.slice(0,3)
    //         .forEach(res =>{
    //             axios({
    //                 method: "post",
    //                 url: "https://tips-api.herokuapp.com/link",
    //                 data: {
    //                   url: res.link
    //                 }
    //               }).then(response => {
    //                 setArticles(response.data.metadata)
    //               });
    //         })
    //         console.log(articles)
    //     })


  return (
    <div id="insider">
     <h2 className="text-center text-black text-2xl font-bold dark:text-white mb-8">INSIDER </h2>

     <div  className="hidden-mobile">
         <BlogWeb articles={articles} />
     </div>

       <div className="lg:hidden">
        <BlogMobile articles={articles}/>
        </div>
    </div>

  )
}

export default Blog