import React from 'react'
import "./PeopleHero.css"
import image from "../../assets/images/Hero2.jpg"

function PeopleHero(props) {


  return (
    <section className="imageContainer">
      <img src={image} style={{width:"100%"}} alt="contact" className="imageSize" />
      <div className="after">
        <div className="textPosition">
          <p className="animate__animated animate__fadeInUp  mb-1 subtitle font-semibold">{props.subtitle}</p>
        <p className="title animate__animated animate__fadeInUp leading-none text-white font-bold textSize">
          {props.title}
          </p>
        </div>

      </div>
    </section>
  )
}

export default PeopleHero