import './App.css';
import { BrowserRouter,Route, Switch } from 'react-router-dom'
import Home from "./Views/Home/Home"
import Contact from './Views/Contact/Contact'
import React from "react"
import People from './Views/People/People';
import Portfolio from './Views/Portfolio/Portfolio'
import ScrollToTop from './ScrollToTop';
import "tailwindcss/tailwind.css"

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <Switch>
    <Route path='/' exact component={Home} />
    <Route path="/contact" component={Contact} />
    <Route path="/people" component={People} />
    <Route path="/portfolio" component={Portfolio} />
    </Switch>
    </BrowserRouter>
  );
}

export default App;
