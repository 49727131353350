import React,{
    useEffect, 
    useState} 
from 'react'
// import {SliderData} from "./SliderData"

import "./Banner.css"
import Button from "../Buttons/Button"
import ButtonOutline from '../Buttons/ButtonOutline'
import image1 from "../../assets/images/olumide-bamgbelu-Ciba8rvHYng-unsplash.jpg"
import image2 from "../../assets/images/namnso-ukpanah-6UqJTfoXIq8-unsplash.jpg"
import image3 from "../../assets/images/ben-iwara-FHo82ziqAaQ-unsplash.jpg"
//import {TransitionGroup,CSSTransition} from 'react-transition-group'
// import styled from "styled-components"

function Carousel({slides}) {
const [current] = useState(0)

const image = [
    {text:"We are an Early Stage Investment for African Entrepreneurs",image:image1},
    {text:"We are an Early Stage Investment for African Entrepreneurs",image:image2},
    {text:"We are an Early Stage Investment for African Entrepreneurs",image:image3},
]

// const length = image.length


// const StyledGrid = styled.div`
// .transition-enter {
//     opacity: 0.01;
//   }
//   .transition-enter-active {
//     opacity: 1;
//     transition: opacity 5000ms ease-in;
//   }
//   .transition-exit {
//     opacity: 1;
//   }
//   .transition-exit-active {
//     opacity: 0.01;
//     transition: opacity 5000ms ease-in;
//   }
// `


// function nextSlide(){
//     setCurrent(current === length -1 ? 0 : current +1)
// }


useEffect(()=>{
    setTimeout(()=>{
        document.getElementById("controllerVisible").style.display="block"
    },2000)
})




    // setTimeout(()=>{ 
    //     nextSlide()
    // },5000)


// function prevSlide(){
//     alert("Non supes approaching")
// }

if(!Array.isArray(slides) || slides.length <= 0 ){
    return null
}



  return (
    <section className="image-container">
        <div id="slide" className="imageContainer">
            {/* <TransitionGroup component={StyledGrid}>
            <CSSTransition  timeout={5000} classNames="transition"> */}
            <div className="imageDiv image3"></div>
                <img src={image[current].image} style={{width:"100%"}} alt="carousel" className="image" />

                
		       <div className="imageDiv image2 "></div>
               <div className="imageDiv image1 fadeInClass"></div>
                {/* </CSSTransition>
                </TransitionGroup> */}
    <div className="after">
                <div id="controllerVisible" className="carouselController dark:bg-transparent">
                <div className="vl"></div>
                <a href="#about"><div className="circle" id="circle0"></div></a>
                <a href="#portfolio"><div className="circle" id="circle1"></div></a>
                <a href="#insider"><div className="circle" id="circle2"></div></a>
                <a href="#footer"><div className="circle" id="circle0" ></div></a>
                </div>
                <div className="textStyle">
                <h1 className="animate__animated animate__fadeInUp animate__delay-1s text-center leading-none text-white font-bold textSize">We are an Early Stage Investment Vehicle for African Entrepreneurs </h1>
                <div className="buttonside justify-center mt-9">
                <div className="mb-5 animate__animated animate__fadeInUp animate__delay-2s mr-3">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSejRzKeMCOUkUWrBbQAAgnoQbNv-RUC4-GgzW9kU4qutUJlSA/viewform"><Button name="Pitch Your Startup" /></a>
                </div>
                <div  className="animate__animated animate__fadeInLeft animate__delay-3s lg:ml-10">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSemcKNwDuGYyqHJpnv6zT9LaGHrM8x9AsuoCaVMGUn2KuMUeQ/viewform"><ButtonOutline  name="Refer a Startup" /></a>
                </div>
                </div>
                    </div>
                    </div>
        </div>
    </section>
  )
}

export default Carousel