import React,{useState} from 'react'
import ArrowCircleRight from '../Buttons/ArrowCircleRight'
import ArrowCircleLeft from "../Buttons/ArrowCircleLeft"
import "./Blog.css"

function ArticleCarousel(props) {

    // const [data, setData] = useState('');
        const [current,setCurrent] = useState(0)
        const length = props.articles.length
        

        function prevSlide(){
            if(current > 0){
            setCurrent(current === length -1 ? 0 : current -1)
            }
        }
        
        function nextSlide(){
            setCurrent(current === length -1 ? 0 : current +1)
        }

  return (
    <div>
        {props.articles && props.articles.map((article,i)=>(
            <div  key={i} onClick={()=>window.location.href=article.metadata.website}>
               {i === current && (<div className="text-center mx-auto w-3/4"><img src={article.metadata.banner} style={{objectFit:"cover",height:"300px"}}  width="100%" alt="img"/> 
               <p className="text-center text-black text-xl font-bold dark:text-white mb-8">{article.metadata.title}</p></div>)
               }
            </div>
        ))
}

<div className="blogContainer">
<ArrowCircleLeft childToParent={prevSlide}/>
<ArrowCircleRight childToParent={nextSlide}/>
</div>


    </div>
  )
}

export default ArticleCarousel