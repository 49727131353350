import React from 'react'
import "./Button.css"
import {CgArrowRight} from "react-icons/cg"

function Button({childToParent}) {
  return (
    <button onClick={() => childToParent()} className="buttonArrow flex">
        <span className="iconPosition"><CgArrowRight /></span>
    </button>
  )
}

export default Button