import React
// ,{useState} 
from 'react'
import "./Portfolio.css"
import PortfolioMobile from "./PortfolioMobile"
import PortfolioWeb from './PortfolioWeb'
import image from "../../assets/images/portfolio.svg"
// import RoundedButton from '../Buttons/RoundedButton'



function Portfolio() {
  // const [loading] = useState(false)

  // function childToParent(){
  //   window.location.href="/portfolio"
  // }
  return (
    <div className="portfolioContainer">
        <h2 id="portfolio"  className="text-center text-black text-2xl font-bold dark:text-white">PORTFOLIO</h2>

        {/* Portfolio Web view */}
        <PortfolioWeb image={image}/>


        {/* Portfolio Mobile view */}
        <div className="titleSpace">
        <PortfolioMobile />
        </div>

        {/* <div className="mb-12 flex justify-center">
  <RoundedButton childToParent={childToParent} loading={loading} url="/portfolio" name="View all Portfolio" />
</div> */}
    </div>
  )
}

export default Portfolio