import React from 'react'
import "./Hero.css"
import image from "../../assets/images/ben-iwara-FHo82ziqAaQ-unsplash.jpg"

function ContactHero(props) {


  return (
    <section className="imageContainer">
      <img src={image} style={{width:"100%"}} alt="contact" className="imageSize" />
      <div className="after">
        <div className="textPosition">
          <p className="animate__animated animate__fadeInUp animate__fast mb-1 subtitle font-semibold">{props.subtitle}</p>
        <p className="title animate__animated animate__fadeInUp leading-none text-white font-bold textSize">
          {props.title}
          </p>
        </div>

      </div>
    </section>
  )
}

export default ContactHero