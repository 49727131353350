import React,{useEffect} from 'react'
import Carousel from "../../Components/Carousel/Carousel"
import NavMenu from "../../Components/NavMenu/NavMenu"
import {SliderData} from "../../Components/Carousel/SliderData"
import About from "../../Components/About/About"
import Portfolio from "../../Components/Portfolio/Portfolio"
import Blog from "../../Components/Blog/Blog"
import Footer from '../../Components/Footer/Footer'
import Aos from 'aos'
import "./Home.css"
import "aos/dist/aos.css"


function Home() {
  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

  return (
    <div className="tellEm" style={{fontFamily:"Inter"}}>
      <NavMenu url="home" className="navStyle"  />

      <div>
      <Carousel slides={SliderData}/>
      </div>
      
      <div style={{marginTop:"150px"}}>
      <About />
      </div>

      <div id="portfolio" data-aos="fade-up" style={{marginTop:"100px"}}>
      <Portfolio/>
      </div>

      <div data-aos="fade-right">
      <Blog />
      </div>

      <div  id="refer">
      <div  data-aos="fade-up" className="dark:bg-gray-900 bg-gray-900 py-7" style={{marginTop:"100px",marginBottom:"-90px"}}>
      <Footer />
      </div>
      </div>

  


    </div>

  )
}

export default Home