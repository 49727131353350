import React,{useEffect} from 'react'
import NavMenu from '../../Components/NavMenu/NavMenu'
import Footer from '../../Components/Footer/Footer'
import Hero from '../../Components/PortfolioHero/PortfolioHero'
import PortfolioGrid from '../../Components/PortfolioGrid/PortfolioGrid'
// import image from "../../assets/images/Company.svg"
import "./Portfolio.css"
import AfropolitanLight from "../../assets/images/Afropolitan 1.png"
import AfropolitanDark from "../../assets/images/Afropolitan 2.png"
import BitnobLight from "../../assets/images/Rectangle-6.png"
import BitnobDark from "../../assets/images/Rectangle-7.png"
import BlocLight from "../../assets/images/bloc-black.png"
import BlocDark from "../../assets/images/bloc-black-1.png"
import BrassLight from "../../assets/images/Rectangle (5).png"
import BrassDark from "../../assets/images/Rectangle-1.png"
import CovaLight from "../../assets/images/Rectangle-2.png"
import CovaDark from "../../assets/images/Rectangle-3.png"
import EarnipayLight from "../../assets/images/earningpayLight.png"
import EarnipayDark from "../../assets/images/earningpayDark.png"
import FloatLight from "../../assets/images/wordmark-dark 2.png"
import FloatDark from "../../assets/images/wordmark-dark 1.png"
import HeyFoodLight from "../../assets/images/HeyFood.png"
import HeyFoodDark from "../../assets/images/HeyFood-1.png"
import KlumpLight from "../../assets/images/Group-2.png"
import KlumpDark from "../../assets/images/Group-3.png"
import EdukoyaLight from "../../assets/images/Group-1.png"
import EdukoyaDark from "../../assets/images/Group.png"
import MyStashLight from "../../assets/images/Mystash.png"
import MyStashDark from "../../assets/images/Mystash-1.png"
import PayDayLight from "../../assets/images/PayDay.png"
import PayDayDark from "../../assets/images/PayDay-1.png"
import WyreOutLight from "../../assets/images/Rectangle-4.png"
import WyreOutDark from "../../assets/images/Rectangle-5.png"
import TixLight from "../../assets/images/Tix-1.png"
import TixDark from "../../assets/images/Tix.png"
import NestCoinLight from "../../assets/images/nestcoin-wordmark-black 2.png"
import NestCoinDark from "../../assets/images/nestcoin-wordmark-black 1.png"
import OkHiLight from "../../assets/images/OkHi.png"
import OkHiDark from "../../assets/images/OkHi-1.png"
import ZeedasLight from "../../assets/images/Zeedas-1.png"
import ZeedasDark from "../../assets/images/Zeedas.png"
import KoaLight from "../../assets/images/Koa.png"
import KoaDark from "../../assets/images/Koa-1.png"
import MoneyMieLight from "../../assets/images/Moneymie.png"
import MoneyMieDark from "../../assets/images/Moneymie-1.png"
import NoreBaseLight from "../../assets/images/Norebase.png"
import NoreBaseDark from "../../assets/images/Norebase-1.png"
import SpleetLight from "../../assets/images/SpleetLight.png"
import SpleetDark from "../../assets/images/SpleetDark.png"
import VoyanceLight from "../../assets/images/download 2.png"
import VoyanceDark from "../../assets/images/download 1.png"
import BoostLight from "../../assets/images/Boost.png"
import BoostDark from "../../assets/images/Boost-1.png"





import Aos from 'aos'
import "aos/dist/aos.css"


function Portfolio() {
  const data = [
    {title:"Afropolitan",image:AfropolitanLight,imageDark:AfropolitanDark,url:"https://afropolitangroup.com",description:"Curating a seamless experience in Travel, Events and media"}, 
    {title:"Bitnob",image:BitnobLight,imageDark:BitnobDark,url:"https://bitnob.com",description:"A delightful way to save, borrow, pay, receive, and get paid in Bitcoin on time"}, 
    {title:"Bloc",image:BlocLight,imageDark:BlocDark,url:"https://www.blochq.io",description:"Building a global infrastructure for African fintechs"}, 
    {title:"Brass",image:BrassLight,imageDark:BrassDark,url:"https://www.trybrass.com",description:"Helping companies of all types work better, save time and money with a simply better banking service that works"}, 
    {title:"Cova",image:CovaLight,imageDark:CovaDark,url:"https://getcova.com",description:"Organize all your assets in one place, track your net worth and securely notify your loved ones in the event of an eventuality"}, 
    {title:"Earnipay",image:EarnipayLight,imageDark:EarnipayDark,url:"https://earnipay.com",description:"Instant access to earned income at zero cost"}, 
    {title:"Edukoya",image:EdukoyaLight,imageDark:EdukoyaDark,url:"https://edukoya.com",description:"A free learning App that helps students master key subjects and prepare for school and university entrance exams"},
    {title:"Float",image:FloatLight,imageDark:FloatDark,url:"https://float.africa",description:"Float helps you manage cashflow and gives you the credit your business needs to cover operational costs like payroll, inventory, or even marketing expense"},
    {title:"HeyFood",image:HeyFoodLight,imageDark:HeyFoodDark,url:"https://heyfood.africa",description:"Your favorite restaurants, delivered to your door"},
    {title:"Klump",image:KlumpLight,imageDark:KlumpDark,url:"https://useklump.com",description:"Klump helps you buy anything you need on a payment plan that works for you"},
    {title:"MyStash",image:MyStashLight,imageDark:MyStashDark,url:"https://mystash.ng",description:"Save a small percentage from your bank account every time you spend or earn money, from any bank account"},
    {title:"PayDay",image:PayDayLight,imageDark:PayDayDark,url:"https://usepayday.com",description:"Send & receive money globally in seconds.  Create virtual Mastercards that work anywhere online"},
    {title:"Wyreout",image:WyreOutLight,imageDark:WyreOutDark,url:"https://wyreout.com",description:"Make fiat payments directly to bank accounts with the aid of stable digital currencies in minutes"},
    {title:"NestCoin",image:NestCoinLight,imageDark:NestCoinDark, url:"https://nestcoin.com",description:"Democratizing access to economic opportunity for everyday people in frontier markets"},
    {title:"Tix.africa",image:TixLight,imageDark:TixDark, url:"https://tix.africa",description:"A self-service ticketing platform for event creators"},
    {title:"OkHi",image:OkHiLight,imageDark:OkHiDark, url:"https://okhi.com",description:"The world’s next generation addressing system"},
    {title:"Zeedas",image:ZeedasLight,imageDark:ZeedasDark, url:"https://zeedas.com",description:"Accurately predict product timelines"},
    {title:"Koa",image:KoaLight,imageDark:KoaDark, url:"https://www.withkoa.com",description:"Digital savings companion for Kenyan millennials"},
    {title:"MoneyMie",image:MoneyMieLight,imageDark:MoneyMieDark, url:"https://moneymie.com",description:"Better banking for African migrants"},
    {title:"Norebase",image:NoreBaseLight,imageDark:NoreBaseDark, url:"https://norebase.com",description:"Start, operate and scale across Africa with no stress"},
    {title:"Spleet",image:SpleetLight,imageDark:SpleetDark, url:"https://spleet.africa",description:"Helping you find the most comfortable living spaces"},
    {title:"Voyance",image:VoyanceLight,imageDark:VoyanceDark, url:"https://voyancehq.com",description:"No code data infrastructure for businesses"},
    {title:"Boost",image:BoostLight,imageDark:BoostDark, url:"https://www.withboost.co/",description:"The B2B commerce platform powering Africa's convenience economy"},
  ]
  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])

  return (
    <div style={{fontFamily:"Inter"}}>
        <NavMenu url={window.location.pathname} />
        <Hero  title="The New Wave of Technology Entrepreneurs Building Africa’s Future"/>
        <div data-aos="fade-up" className="portfolioGrid" style={{marginTop:"79px"}}>
        {data && data.map((data,i)=>(
        <div key={i}>
        <PortfolioGrid title={data.title} image={data.image} imageDark={data.imageDark} url={data.url} description={data.description}/>
        </div>
        ))}
        </div>
        <div data-aos="fade-up" className="bg-gray-900 py-7" style={{marginTop:"100px",marginBottom:"-90px"}}>
        <Footer />
        </div>
    </div>
  )
}

export default Portfolio