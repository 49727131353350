import React from 'react'
import backgroundImage from "../../assets/images/Group (1).svg"
import "./Blog.css"
import ArticleCarousel from './ArticleCarousel'

function BlogMobile(props) {
  return (
    <div>

        <div className="blogImageContainer">
            <img src={backgroundImage} alt="img" style={{width:"100%",height:"100%"}}/>
            <div className="mx-auto w-1/2">
            <div className="after">
                <ArticleCarousel articles={props.articles} />
                </div>
            </div>
        </div>


    </div>
  )
}

export default BlogMobile