import React,{useEffect} from 'react'
import "./Portfolio.css"
// import Afropolitan from "../../assets/images/Afropolitan.png"
import AfropolitanLight from "../../assets/images/Afropolitan 1.png"
import AfropolitanDark from "../../assets/images/Afropolitan 2.png"
import BitnobLight from "../../assets/images/Rectangle-6.png"
import BitnobDark from "../../assets/images/Rectangle-7.png"
import BlocLight from "../../assets/images/bloc-black.png"
import BlocDark from "../../assets/images/bloc-black-1.png"
import BrassLight from "../../assets/images/Rectangle (5).png"
import BrassDark from "../../assets/images/Rectangle-1.png"
import CovaLight from "../../assets/images/Rectangle-2.png"
import CovaDark from "../../assets/images/Rectangle-3.png"
import EarnipayLight from "../../assets/images/earningpayLight.png"
import EarnipayDark from "../../assets/images/earningpayDark.png"
import FloatLight from "../../assets/images/wordmark-dark 2.png"
import FloatDark from "../../assets/images/wordmark-dark 1.png"
import HeyFoodLight from "../../assets/images/HeyFood.png"
import HeyFoodDark from "../../assets/images/HeyFood-1.png"
import KlumpLight from "../../assets/images/Group-2.png"
import KlumpDark from "../../assets/images/Group-3.png"
import EdukoyaLight from "../../assets/images/Group-1.png"
import EdukoyaDark from "../../assets/images/Group.png"
import MyStashLight from "../../assets/images/Mystash.png"
import MyStashDark from "../../assets/images/Mystash-1.png"
import "aos/dist/aos.css"

import Aos from 'aos'


function PortfolioWeb() {
  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])
  return (
    <div>
<div className="cursor-pointer grid-body hidden-mobile">
  <div className="grid-container">
  <div data-aos="fade-up" className="grid-item p-3" onClick={()=>window.location.href="https://afropolitangroup.com"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? AfropolitanLight : AfropolitanDark} alt="portfolioImg" /></div>
  <div  data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://bitnob.com"}><img style={{objectFit:"contain",margin:"auto",width:"150px"}} src={localStorage.getItem("color-theme") === "dark" ? BitnobLight : BitnobDark} alt="portfolioImg" /></div>  
  <div  data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://www.blochq.io"}><img style={{objectFit:"contain",margin:"auto",width:"150px"}} src={localStorage.getItem("color-theme") === "dark" ? BlocLight : BlocDark} alt="portfolioImg" /></div>
  <div  data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://www.trybrass.com"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? BrassLight : BrassDark} alt="portfolioImg" /></div>
  <div  data-aos="fade-up" className="grid-item p-3" onClick={()=>window.location.href="https://getcova.com"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? CovaLight : CovaDark} alt="portfolioImg" /></div>  
  <div data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://earnipay.com"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? EarnipayLight : EarnipayDark} alt="portfolioImg" /></div>
  <div  data-aos="fade-up" className="grid-item p-3" onClick={()=>window.location.href="https://edukoya.com"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? EdukoyaLight : EdukoyaDark} alt="portfolioImg" /></div>
  <div  data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://float.africa"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? FloatLight : FloatDark} alt="portfolioImg" /></div>  
  <div  data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://heyfood.africa"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? HeyFoodLight : HeyFoodDark} alt="portfolioImg" /></div>  
  <div  data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://useklump.com"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? KlumpLight : KlumpDark} alt="portfolioImg" /></div>  
  <div  data-aos="fade-up"  className="grid-item p-3" onClick={()=>window.location.href="https://mystash.ng"}><img style={{objectFit:"contain"}} src={localStorage.getItem("color-theme") === "dark" ? MyStashLight : MyStashDark} alt="portfolioImg" /></div>  
</div>
</div>
    </div>
  )
}

export default PortfolioWeb