import React from 'react'
import "./Features.css"

function Features(props) {
  return (
    <div className="homefeatureContainer">
    <div className="featureBox">
            <div className="flex justify-center">
            <img  src={props.image} alt="img"/>
            </div>
            <div className="text-center">
            <h1 className="my-5 font-medium featureTitle text-black dark:text-white">{props.title}</h1>
            <p className="featureText text-black dark:text-white">{props.description}</p>
            </div>
    </div>
    </div>
  )
}

export default Features