import React,{useEffect} from 'react'
import "./PeopleCard.css"
import {FaLinkedinIn} from "react-icons/fa"
import Aos from 'aos'
import "aos/dist/aos.css"


function PeopleCard(props) {

  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])

  return (
    <div className="personContainer">
        <div className="personCard">
            <img src={props.image} alt="team" className="border-2 border-gray-900 dark:border-white" style={{borderRadius:"5px",width:"340px",height:"400px",objectFit:"cover"}}/>
            <div className="after">
            <div className="team-details">
                <h2 className="uppercase font-bold text-md">{props.name}</h2>
                <p className="text-center mt-1">{props.role}</p>
                <div onClick={()=>window.location.href=props.linkedin} className="cursor-pointer flex justify-center mt-3"><FaLinkedinIn /></div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default PeopleCard