import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
  color: #fff;
  margin: 0px;
  padding:0px 10px 0px 10px;
  font-size: 4em;
  box-shadow:0px 0px 0 0px rgba(0, 0, 0, 0.8);
  opacity:1;
  background: linear-gradient(226.03deg, rgba(255, 255, 255, 0.3) -115.11%, rgba(255, 255, 255, 0.1) 247.28%);
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  border-radius: 5px;
`;
