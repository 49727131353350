import React,{useEffect} from 'react'
import "./PeopleCard.css"
import {FaLinkedinIn} from "react-icons/fa"
import Aos from 'aos'
import "aos/dist/aos.css"

function PeopleCircle(props) {
  
  useEffect(()=>{
    Aos.init({duration:1000 })
  },[])

  return (
    <div>
        <div data-aos="fade-up" className="circleContainer">
            <div className="circleOutline">
            <img className="image" style={{objectFit:"cover",height:"200px",width:"200px"}} src={props.image} alt="img"/>
            </div>
        </div>
        <div data-aos="fade-up" className="text-center my-6">
        <h2 style={{color:"#0077FF"}} className="text-center mt-2 uppercase text-xl font-bold">{props.name}</h2>
        <p className="dark:text-white">{props.role}</p>
        <div onClick={()=>window.location.href=props.linkedin} className="flex cursor-pointer justify-center mt-2"><FaLinkedinIn className="dark:text-white" /></div>
        </div>
    </div>
  )
}

export default PeopleCircle