import React from 'react'
import "./Button.css"
import {CgArrowRight} from "react-icons/cg"

function Button(props) {
  return (
    <button onClick={() => window.location.href=props.url} className="buttonCircle flex">
        <span style={{marginTop:"4px"}} className="text-sm circleHover">Get Started</span><span className="iconPosition"><CgArrowRight /></span>
    </button>
  )
}

export default Button