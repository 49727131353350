import React,{useState} from 'react'
import RoundedButton from '../Buttons/RoundedButton'
import axios from 'axios'
import Swal from "sweetalert2";  
import "./Footer.css"

function Footer() {

  const [email,setEmail] = useState("")
  const [loading,setLoading] = useState(false)

  function childToParent(){
    if(email ===""){
      Swal.fire({
        title: "Please enter an email",
        text:
          "You need to enter the email of the startup you want to refer",
        icon: "error",
        confirmButtonText: "Okay",
        confirmButtonColor:"#0077FF"
      })
    }
   else{
     setLoading(true)
    axios({
      method:"post",
      url:"https://kickoffapi.herokuapp.com/mail",
      data:{
        email:email
      }
    }).then(()=>{
      setLoading(false)
      Swal.fire({
        title: "Thanks for the referral!",
        text:
          "We have successfully sent out an email to the startup you referred.",
        icon: "success",
        confirmButtonText: "Okay",
        confirmButtonColor:"#0077FF"
      });
    })
  }
}

  return (
    <div id="footer" style={{height:"600px",margin:"100px 0px auto 0px"}}>
        <div className="lg:flex justify-center">
        <div>
        <h2 className="mt-5 text-center ml-9 text-black text-xl font-medium text-white mb-8 mr-7">REFER A STARTUP</h2>
        </div>
        <div className="text-center formContainer mr-6">
        <input onChange={(e)=>setEmail(e.target.value)} type="text" className="dark:bg-black max-w-lg" name="email" label="Email" placeholder="Email" />
        </div>
        <div className="sendy justify-center mt-2">
        <RoundedButton childToParent={childToParent} loading={loading} name="Send"/>
        </div>
        </div>

        <div className="lg:hidden">
        <div className="ml-9 mt-12 flex justify-around">
            <h2 className="mt-3 text-center font-bold text-white mb-8 mr-7"> LAGOS</h2>
            <h2 className="mt-3 text-center font-bold text-white mb-8 mr-7"> LONDON</h2>
        </div>
        <div>
        <div className="p-5 flex justify-center">
        <button onClick={()=>window.location.href="mailto:fola@kickoff.africa"} className="contactButton w-full max-w-lg mt-3 text-center text-white font-bold dark:bg-black mb-8" >Contact Us</button>
        </div>
        <h2 className="mt-3 text-center ml-9  font-bold text-white mb-8 mr-7"> LEGAL NOTICE</h2>
        <h2 className="mt-3 text-center ml-9  text-white mb-8 mr-7">All Rights Reserved Kickoff.africa 2022</h2>
        </div>
        </div>

        <div style={{marginTop:"200px"}} className="hidden-mobile">
        <div className="ml-9 mt-12 flex justify-around">
            <h2 className="mt-3 text-center font-bold text-white mb-8 mr-7"> LAGOS</h2>
            <h2 className="mt-3 text-center font-bold text-white mb-8 mr-7"> LONDON</h2>
            <div >
        <button onClick={()=>window.location.href="mailto:dolapo@kickoff.africa"} className="w-full hover:bg-white hover:text-blue-600 contactButton text-center ml-2 text-white font-bold bg-gray-900 mb-8 mr-7" >Contact Us</button>
        </div>
        </div>
        <div>
        <hr/>

        <div className="flex pt-9">
         <h2 className="w-2/6 mt-3 text-center ml-9 text-gray-900 font-bold text-white mr-7"> Emputee</h2>
        <h2 className="w-1/4 mt-3 text-center font-bold text-white mr-7"> LEGAL NOTICE</h2>
        <h2 className="mt-3 text-center ml-12 text-white">All Rights Reserved Kickoff.africa 2022</h2>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Footer